import React from 'react';
import { Field } from 'formik';
import smsSenderTypes from '../../helpers/smsSenderTypesForSelect';
import InnerSelectField from './InnerSelectField';

const SmsSendersSelectField = (props) => (
  <Field
    name={props.name}
    options={smsSenderTypes.map((s) => {
      return { value: s.id, label: s.name };
    })}
    component={InnerSelectField}
    {...props}
  />
);

export default SmsSendersSelectField;
