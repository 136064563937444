import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: '',
  currentUser: '',
  isLoading: false,
  loginErrors: [],
};

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    loginSucceeded: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.currentUser = action.payload.currentUser;
      state.refreshToken = action.payload.refreshToken;
      state.accessTokenExpirationDate = action.payload.accessTokenExpirationDate;
      state.isLoading = false;
      state.loginErrors = [];
    },
    requestPending: (state, action) => {
      state.isLoading = action.payload;
    },
    loginFailed: (state, action) => {
      state.loginErrors = action.payload.errors;
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = loginSlice;

export const { loginSucceeded, requestPending, loginFailed } = actions;

export default reducer;
