import React from 'react';
import Button from '@material-ui/core/Button';
import InputField from '../../fields/Input/InputField';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Form } from 'formik';
import { ImageUploadField } from '../../fields/ImageUploadField';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
});

const UpdateSellerForm = (props) => {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <Form className={classes.flexContainer}>
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'h5'}>
          {t('Basic information')}
        </Typography>
      </div>
      <InputField
        color={'secondary'}
        className={classes.input}
        name="name"
        type="text"
        label={t('Seller name')}
        variant="outlined"
      />
      <InputField
        color={'secondary'}
        className={classes.input}
        type="string"
        name="firstOrderPricingCoefficient"
        label={t('First order pricing coefficient')}
        variant="outlined"
      />
      <InputField
        color={'secondary'}
        className={classes.input}
        name="timeZone"
        type="text"
        label={t('Timezone')}
        variant="outlined"
      />
      <ImageUploadField permittedExtensions={['.png']} name="logoUrl" variant="outlined" />
      <div className={classes.childElement}>
        <Button variant="contained" type="submit" className={classes.button}>
          {t('Save')}
        </Button>
      </div>
    </Form>
  );
};

export default withStyles(styles)(UpdateSellerForm);
