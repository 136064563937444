import React from 'react';
import { Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import InputField from '../../fields/Input/InputField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import SmsSendersSelectField from '../../fields/Select/SmsSendersSelectField';
import { customStyles } from '../../fields/Select/InnerSelectField';
import smsSenderTypes from '../../helpers/smsSenderTypes';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
});

const UpdateSellerNotificationsDataForm = (props) => {
  const { classes, formikProps } = props;
  const { t } = useTranslation();

  return (
    <Form className={classes.flexContainer}>
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'h5'}>
          {t('Notification data')}
        </Typography>
      </div>
      <SmsSendersSelectField
        styles={customStyles}
        color={'secondary'}
        className={classes.input}
        name="smsSender"
        placeholder={t('SMS provider')}
        variant="outlined"
      />
      {formikProps.values.smsSender.value === smsSenderTypes.Twilio && (
        <React.Fragment>
          <InputField
            color={'secondary'}
            className={classes.input}
            name="accountSid"
            type="text"
            label="Account SID"
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="authToken"
            type="password"
            label="Auth Token"
            autoComplete="new-password"
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="senderPhoneNumber"
            type="text"
            label={t('Sender phone number')}
            variant="outlined"
          />
        </React.Fragment>
      )}
      {formikProps.values.smsSender.value === smsSenderTypes.SmsRu && (
        <React.Fragment>
          <InputField
            color={'secondary'}
            className={classes.input}
            name="apiId"
            type="password"
            label="API Id"
            autoComplete="new-password"
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="senderName"
            type="text"
            label={t('Sender name')}
            variant="outlined"
          />
        </React.Fragment>
      )}
      {formikProps.values.smsSender.value === smsSenderTypes.Infosintez && (
        <React.Fragment>
          <InputField
            color={'secondary'}
            className={classes.input}
            name="infosintezAccountConfig.login"
            type="text"
            label={t('Login')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="infosintezAccountConfig.password"
            type="password"
            autoComplete="new-password"
            label={t('Password')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="infosintezAccountConfig.sender"
            type="text"
            label={t('Sender name')}
            variant="outlined"
          />
        </React.Fragment>
      )}
      <div className={classes.childElement}>
        <Button variant="contained" type="submit" className={classes.button}>
          {t('Save')}
        </Button>
      </div>
    </Form>
  );
};

export default withStyles(styles)(UpdateSellerNotificationsDataForm);
