import {
  notificationsDataFetched,
  paymentDataFetched,
  requestPending,
  sellerAdminsFetched,
  sellerApiTokenDateRemoved,
  sellerApiTokenFetched,
  sellerByIdFetched,
  sellersFetched,
  sellerBonusesConfig,
} from './sellersReducers';
import axios from 'axios';
import paginationConstants from '../../../components/helpers/paginationConstants';
import { getErrorsForNotificationPopup } from '../../../components/helpers/getErrorsForNotificationPopup';
import paymentGatewayTypes from '../../../components/helpers/paymentGatewayTypes';
import smsSenderTypes from '../../../components/helpers/smsSenderTypes';

export const getSellers = (pageNumber) => (dispatch) => {
  dispatch(requestPending(true));
  return axios
    .get(`/sellers?pageNumber=${pageNumber}&itemsPerPage=${paginationConstants.itemsPerPage}`)
    .then((response) => dispatch(sellersFetched(response.data)));
};

export const addSellerAccount = (seller, sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .post(`/sellers/${sellerId}/admins`, {
      email: seller.email,
      firstName: seller.firstName,
      lastName: seller.lastName,
      password: seller.password,
    })
    .then((response) => {
      dispatch(requestPending(false));
    })
    .catch((error) => {
      dispatch(requestPending(false));
      const errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      throw errorMessage;
    });
};

export const addSeller = (seller) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .post(`/sellers`, {
      seller: {
        id: seller.id,
        name: seller.name,
        firstOrderPricingCoefficient: Number(seller.pricingCoefficient),
        timezone: seller.timezone,
        logoUrl: seller.imageUrl,
      },
    })
    .then((response) => {
      dispatch(requestPending(false));
    })
    .catch((error) => {
      dispatch(requestPending(false));
      const errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      throw errorMessage;
    });
};

export const updateSellerPaymentData = (paymentData, sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .put(`/sellers/${sellerId}/paymentdata`, {
      authorizationAmount: Number(paymentData.authorizationAmount),
      paymentGateway: paymentData.paymentGateway.value,
      currency: paymentData.currency,
      bePaidAccountConfig:
        paymentData.paymentGateway.value === paymentGatewayTypes.bePaid
          ? {
              privateKey: paymentData.privateKey,
              publicKey: paymentData.publicKey,
              shopId: paymentData.shopId,
            }
          : null,
      cloudPaymentsAccountConfig:
        paymentData.paymentGateway.value === paymentGatewayTypes.cloudPayments
          ? {
              apiSecret: paymentData.apiSecret,
              publicId: paymentData.publicId,
            }
          : null,
      sberbankAccountConfig:
        paymentData.paymentGateway.value === paymentGatewayTypes.sberbank
          ? {
              userName: paymentData.userName,
              password: paymentData.password,
            }
          : null,
    })
    .then((response) => {
      dispatch(requestPending(false));
    })
    .catch((error) => {
      dispatch(requestPending(false));
      const errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      throw errorMessage;
    });
};

export const updateSellerNotificationsData = (notificationsData, sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .put(`/sellers/${sellerId}/notificationsData`, {
      smsSender: notificationsData.smsSender.value,
      twilioAccountConfig:
        notificationsData.smsSender.value === smsSenderTypes.Twilio
          ? {
              accountSid: notificationsData.accountSid,
              authToken: notificationsData.authToken,
              senderPhoneNumber: notificationsData.senderPhoneNumber,
            }
          : null,
      smsRuAccountConfig:
        notificationsData.smsSender.value === smsSenderTypes.SmsRu
          ? {
              apiId: notificationsData.apiId,
              senderName: notificationsData.senderName,
            }
          : null,
      infosintezAccountConfig:
        notificationsData.smsSender.value === smsSenderTypes.Infosintez
          ? {
              login: notificationsData.infosintezAccountConfig.login,
              password: notificationsData.infosintezAccountConfig.password,
              sender: notificationsData.infosintezAccountConfig.sender,
            }
          : null,
    })
    .then((response) => {
      dispatch(requestPending(false));
    })
    .catch((error) => {
      dispatch(requestPending(false));
      const errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      throw errorMessage;
    });
};

export const removeSellerApiTokenData = () => (dispatch) => {
  dispatch(sellerApiTokenDateRemoved());
};

export const getSellerNotificationsData = (sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios.get(`/sellers/${sellerId}/notificationsData`).then((response) => {
    dispatch(notificationsDataFetched(response.data));
  });
};

export const getSellerApiToken = (sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios.get(`/sellers/${sellerId}/public-api/token`).then((response) => {
    dispatch(
      sellerApiTokenFetched({
        apiToken: response.data,
        sellerId: sellerId,
      }),
    );
  });
};

export const getSellerPaymentData = (sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios.get(`/sellers/${sellerId}/paymentdata`).then((response) => {
    dispatch(paymentDataFetched(response.data));
  });
};

export const getSellerAdmins = (sellerId, pageNumber) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .get(`/sellers/${sellerId}/admins?pageNumber=${pageNumber}&itemsPerPage=${paginationConstants.itemsPerPage}`)
    .then((response) => dispatch(sellerAdminsFetched(response.data)));
};

export const changeSellerPassword = (userId, newPassword) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .put(`/sellers/${userId}`, {
      newPassword: newPassword,
    })
    .then((response) => {
      dispatch(requestPending(false));
    })
    .catch((error) => {
      dispatch(requestPending(false));
      const errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      throw errorMessage;
    });
};

export const getSellerById = (sellerId, history) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .get(`/sellers/${sellerId}`)
    .then((response) => {
      dispatch(sellerByIdFetched(response.data));
    })
    .catch((error) => {
      dispatch(requestPending(false));
      history.push('/sellers');
    });
};

export const getDeliverySellerById = (sellerId, history) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .get(`delivery/sellers/${sellerId}`)
    .then((response) => {
      dispatch(sellerByIdFetched(response.data));
    })
    .catch((error) => {
      dispatch(requestPending(false));
      history.push('/sellers');
    });
};

export const getSellerBonusesConfig = (sellerId, history) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .get(`/sellers/${sellerId}/bonusesConfig`)
    .then((response) => {
      dispatch(sellerBonusesConfig(response.data));
    })
    .then(() => {
      dispatch(requestPending(false));
    });
};

export const updateSeller = (seller) => (dispatch) => {
  dispatch(requestPending(true));

  return axios
    .put(`/sellers`, {
      seller: {
        id: seller.id,
        name: seller.name,
        firstOrderPricingCoefficient: Number(seller.firstOrderPricingCoefficient),
        timezone: seller.timeZone,
        logoUrl: seller.logoUrl,
      },
    })
    .then(() => {
      dispatch(requestPending(false));
    });
};

export const updateSellerContactInfo = (contactInfo, sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios.put(`/sellers/${sellerId}/contactInfo`, contactInfo).then(() => {
    dispatch(requestPending(false));
  });
};

export const updateSellerDeliveryContactInfo = (contactInfo, sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios.put(`delivery/sellers/${sellerId}/contactInfo`, contactInfo).then(() => {
    dispatch(requestPending(false));
  });
};

export const updateSellerBonusesConfiguration = (bonusesConfig, sellerId) => (dispatch) => {
  dispatch(requestPending(true));

  return axios.put(`/sellers/${sellerId}/bonusesConfig`, bonusesConfig).then(() => {
    dispatch(requestPending(false));
  });
};
