import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  accountErrors: [],
};

const accountSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    requestSucceeded: (state) => {
      state.isLoading = false;
      state.accountErrors = [];
    },
    requestPending: (state, action) => {
      state.isLoading = action.payload;
    },
    requestFailed: (state, action) => {
      state.isLoading = false;
      state.accountErrors = action.payload.errors;
    },
  },
});

const { actions, reducer } = accountSlice;

export const { requestSucceeded, requestPending, requestFailed } = actions;

export default reducer;
