import React from 'react';
import Button from '@material-ui/core/Button';
import InputField from '../../fields/Input/InputField';
import { withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import Typography from '@material-ui/core/Typography';
import { addSellerAccount } from '../../../redux/modules/sellers/sellersActions';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
});

const AddSellerAccountForm = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const sellerId = getRouteParamFromUrl(match, 'id');
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const { t } = useTranslation();

  return isLoading ? (
    <Spinner />
  ) : (
    <Formik
      onSubmit={(values, actions) => {
        return dispatch(addSellerAccount(values, sellerId))
          .then(() => {
            history.push('/sellers');
          })
          .catch((err) => {
            showNotification(notificationTypes.error, err, t('Changes were not saved'));
          });
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(7, t(validationErrors.minPasswordLength))
          .required(t(validationErrors.fieldCantBeEmpty)),
        firstName: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
        lastName: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
        email: Yup.string().email(t(validationErrors.wrongEmailFormat)).required(t(validationErrors.fieldCantBeEmpty)),
      })}
      initialValues={{
        password: '',
        firstName: '',
        lastName: '',
        email: '',
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit} className={classes.flexContainer}>
          <div className={classes.childElement}>
            <Typography className={classes.title} color={'primary'} variant={'h5'}>
              {t('Creating seller admin account for {{sellerId}} application', { sellerId: sellerId })}
            </Typography>
          </div>
          <InputField
            color={'secondary'}
            className={classes.input}
            name="firstName"
            type="text"
            label={t('First name')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="lastName"
            type="text"
            label={t('Second name')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            type="email"
            name="email"
            label={t('Email address')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="password"
            type="password"
            label={t('Password')}
            variant="outlined"
          />
          <div className={classes.childElement}>
            <Button variant="contained" type="submit" className={classes.button}>
              {t('Create account')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(AddSellerAccountForm);
