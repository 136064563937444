import axios from 'axios';
import { showNotification } from '../components/helpers/notificationHelper';
import notificationTypes from '../components/helpers/notificationTypes';

export const getImageUrl = (data) => {
  return axios
    .post('/sellers/image', data)
    .then((response) => response)
    .catch((response) => {
      if (response.response && response.response.status === 415) {
        showNotification(
          notificationTypes.warning,
          'Пожалуйста, выберете изображение в допустимом формате',
          'Неподдерживаемый формат',
        );
      } else {
        showNotification(
          notificationTypes.error,
          'Произошла ошибка при загрузке изображения. Проверьте, что размер изображения не превышает 30 КБ.',
          'Изображение не загружено',
        );
      }

      return response;
    });
};
