import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import smsSenderTypes from '../../helpers/smsSenderTypes';
import i18next from 'i18next';
import regularExpressions from '../../helpers/regularExpressions';

const t = i18next.t;

const buildRequiredDependingOnSmsSender = (smsSender) => ({
  is: (field) => field && field.value === smsSender,
  then: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
});

const validationShape = () => {
  return {
    smsSender: Yup.object().required(t(validationErrors.fieldCantBeEmpty)),
    accountSid: Yup.string().when('smsSender', buildRequiredDependingOnSmsSender(smsSenderTypes.Twilio)),
    authToken: Yup.string().when('smsSender', buildRequiredDependingOnSmsSender(smsSenderTypes.Twilio)),
    senderPhoneNumber: Yup.string()
      .matches(regularExpressions.phonueNumberRegEx, t(validationErrors.wrongPhoneNumberFormat))
      .when('smsSender', buildRequiredDependingOnSmsSender(smsSenderTypes.Twilio)),
    apiId: Yup.string().when('smsSender', buildRequiredDependingOnSmsSender(smsSenderTypes.SmsRu)),
    infosintezAccountConfig: Yup.object().when('smsSender', {
      is: (field) => field && field.value === smsSenderTypes.Infosintez,
      then: Yup.object({
        login: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
        password: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
        sender: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
      }),
    }),
  };
};

export { validationShape };
