export default {
  fieldCantBeEmpty: 'Please fill in this field',
  fieldShouldBePositiveNumber: 'This field must be numeric and positive',
  fieldShouldBeNonNegativeNumber: 'This field must be numeric and non-negative',
  minPasswordLength: 'The password must be at least 7 characters long',
  wrongEmailFormat: 'Please enter a valid email address',
  wrongPhoneNumberFormat: 'The phone number is incorrect',
  numberMustBeInRange: 'The number must be between {{min}} and {{max}}',
  passwordsMustMatch: 'Passwords must match',
};
