import * as queryString from 'query-string';

export const getQueryParamFromUrl = (location, paramName) => {
  const url = location.search;
  return queryString.parse(url)[paramName];
};

export const getRouteParamFromUrl = (match, paramName) => {
  const url = match.params;
  return url[paramName];
};
