import { requestSucceeded, requestPending, requestFailed } from './accountReducers';
import { getErrorsForNotificationPopup } from '../../../components/helpers/getErrorsForNotificationPopup';
import axios from 'axios';

export const changePassword = (values) => (dispatch) => {
  dispatch(requestPending(true));
  return axios
    .put('/account/change-password', {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    })
    .then(() => dispatch(requestSucceeded()))
    .catch((err) => {
      dispatch(requestFailed(err.response.data));
      const errorMessage = getErrorsForNotificationPopup(err.response.data.errors);
      throw errorMessage;
    });
};
