import React from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import paginationConstants from '../../helpers/paginationConstants';
import commonStyles from '../../helpers/commonStyles';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { toLongDateTime } from '../../helpers/dateFormatter';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  table: {
    minWidth: '1300px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '1300px',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
  redirectLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.black,
  },
  actionsFormContainer: {
    minWidth: '90px',
    width: '100px',
  },
});

const SellerAdminsTable = (props) => {
  const { classes, admins, handlePageChange, adminsCount, activePage, history } = props;
  const { t } = useTranslation();

  const pagesCount = Math.ceil(adminsCount / paginationConstants.itemsPerPage);

  const changePassword = (adminId) => {
    history.push(`/sellers/admins/${adminId}/password`);
  };

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.tableHeader}>{t('Email address')}</TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('First name')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Second name')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Registration date')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {admins && admins.length ? (
            admins.map((admin) => (
              <TableRow key={admin.userId} className={classes.tableContent}>
                <TableCell component="th" scope="row">
                  {admin.email}
                </TableCell>
                <TableCell align="right">{admin.firstName}</TableCell>
                <TableCell align="right">{admin.lastName}</TableCell>
                <TableCell align="right">{toLongDateTime(admin.registrationDate)}</TableCell>
                <TableCell align="right">
                  <Button variant={'outlined'} onClick={() => changePassword(admin.userId)} startIcon={<EditIcon />}>
                    {t('Change password')}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className={classes.tableContent}>
              <TableCell align={'center'} colSpan={5}>
                {t('There are no accounts for this application')}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Pagination
        className={classes.paginationContainer}
        size={'large'}
        variant="outlined"
        shape="rounded"
        count={pagesCount}
        page={activePage}
        onChange={handlePageChange}
        color="primary"
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(SellerAdminsTable);
