import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import languageRU from './localization/ru/translate.json';
import XHR from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ru: languageRU,
    },
    lng: 'ru',
    fallbackLng: 'ru',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '##',
    nsSeparator: '::',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
