import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sellers: [],
  isLoading: false,
  sellersCount: 1,
  paymentData: {},
  sellerAdmins: [],
  sellerAdminsCount: 1,
  selectedSeller: {},
  selectedSellerId: null,
  notificationsData: {},
  sellerApiToken: null,
};

const sellersSlice = createSlice({
  name: 'sellersSlice',
  initialState,
  reducers: {
    sellersFetched: (state, action) => {
      state.sellers = action.payload.items;
      state.sellersCount = action.payload.totalItems;
      state.isLoading = false;
    },
    sellerAdminsFetched: (state, action) => {
      state.sellerAdmins = action.payload.items;
      state.sellerAdminsCount = action.payload.totalItems;
      state.isLoading = false;
    },
    paymentDataFetched: (state, action) => {
      state.paymentData = action.payload;
      state.isLoading = false;
    },
    notificationsDataFetched: (state, action) => {
      state.notificationsData = action.payload;
      state.isLoading = false;
    },
    sellerByIdFetched: (state, action) => {
      state.selectedSeller = action.payload;
      state.isLoading = false;
    },
    sellerApiTokenFetched: (state, action) => {
      state.sellerApiToken = action.payload.apiToken;
      state.selectedSellerId = action.payload.sellerId;
      state.isLoading = false;
    },
    sellerApiTokenDateRemoved: (state, action) => {
      state.sellerApiToken = null;
      state.selectedSellerId = null;
    },
    sellerBonusesConfig: (state, action) => {
      state.selectedSeller = {
        ...state.selectedSeller,
        percentageOfAccrualPerOrder: action.payload.percentageOfAccrualPerOrder,
        maxPercentageToPayWithBonuses: action.payload.maxPercentageToPayWithBonuses,
      };
    },
    requestPending: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

const { actions, reducer } = sellersSlice;

export const {
  sellersFetched,
  requestPending,
  paymentDataFetched,
  sellerAdminsFetched,
  sellerByIdFetched,
  notificationsDataFetched,
  sellerApiTokenFetched,
  sellerApiTokenDateRemoved,
  sellerBonusesConfig,
} = actions;

export default reducer;
