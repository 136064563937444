import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';

const InnerInputField = ({ classname, variant, label, field, form, ...props }) => {
  const [formField, meta] = useField(field.name);
  let styles = {};
  styles.fontSize = 14;
  const innerProps = { style: styles };

  return (
    <React.Fragment>
      <TextField
        inputProps={innerProps}
        className={classname}
        variant={variant}
        label={label}
        {...props}
        {...formField}
      />
      {(meta.touched || form.submitCount > 0) && meta.error && (
        <span style={{ color: 'red' }} {...props}>
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

export default InnerInputField;
