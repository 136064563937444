import { getQueryParamFromUrl } from './getParamsFromUrl';
import moment from 'moment';
import dateFormats from './dateFormats';

export const getPageNumber = (location) => {
  const pageNumberQuery = getQueryParamFromUrl(location, 'page');

  if (pageNumberQuery && !isNaN(Number(pageNumberQuery))) {
    return Number(pageNumberQuery);
  } else {
    return 1;
  }
};

export const getSearchString = (props) => {
  const searchStringQuery = getQueryParamFromUrl(props, 'searchString');

  if (searchStringQuery) {
    return searchStringQuery;
  } else {
    return '';
  }
};

export const getArrayParam = (props, paramName) => {
  const query = getQueryParamFromUrl(props, paramName);

  if (query) {
    if (Array.isArray(query)) {
      return query;
    } else {
      return new Array(query);
    }
  } else {
    return [];
  }
};

export const getDateParam = (props, paramName) => {
  const dateQuery = getQueryParamFromUrl(props, paramName);

  const queryDate = moment(dateQuery, dateFormats.shortDateTime);

  if (queryDate && queryDate.isValid()) {
    return dateQuery;
  } else {
    return null;
  }
};
