import React, { useEffect, useState } from 'react';
import { TableRow, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Pagination from '@material-ui/lab/Pagination';
import paginationConstants from '../../helpers/paginationConstants';
import commonStyles from '../../helpers/commonStyles';
import ActionsMenu from '../../common/ActionsMenu';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import ApiTokenModal from './ApiTokenModal';
import { getSellerApiToken, removeSellerApiTokenData } from '../../../redux/modules/sellers/sellersActions';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  table: {
    minWidth: '1300px',
  },
  headerRow: {
    backgroundColor: theme.palette.whiteblue,
  },
  tableContent: {
    backgroundColor: theme.palette.white,
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '1300px',
  },
  tableHeader: {
    borderTop: commonStyles.tableHeaderTopBorder,
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    color: theme.palette.white,
  },
  redirectLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.black,
  },
  actionsFormContainer: {
    minWidth: '90px',
    width: '100px',
  },
});

const SellersTable = (props) => {
  const { classes, sellers, handlePageChange, sellersCount, activePage, history } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sellerApiToken = useSelector((state) => state.sellers.sellerApiToken);
  const sellerId = useSelector((state) => state.sellers.selectedSellerId);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSellerId, setSelectedSellerId] = useState(null);
  const [selectedApiToken, setSelectedApiToken] = useState(null);

  const handleOpenModal = (sellerId, sellerApiToken) => {
    setIsModalOpen(true);
    setSelectedSellerId(sellerId);
    setSelectedApiToken(sellerApiToken);
  };

  useEffect(() => {
    if (sellerApiToken) {
      handleOpenModal(sellerId, sellerApiToken);
    }
  }, [sellerApiToken, sellerId]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    dispatch(removeSellerApiTokenData());
  };

  const handleCopyApiToken = () => {
    copy(sellerApiToken);
  };

  const generateSellerApiToken = (sellerId) => {
    dispatch(getSellerApiToken(sellerId));
  };

  const pagesCount = Math.ceil(sellersCount / paginationConstants.itemsPerPage);

  const addAccount = (sellerId) => {
    history.push(`/sellers/${sellerId}/admins/add`);
  };
  const updatePaymentData = (sellerId) => {
    history.push(`/sellers/${sellerId}/paymentData`);
  };
  const updateNotificationsData = (sellerId) => {
    history.push(`/sellers/${sellerId}/notificationsData`);
  };
  const updateContactInfo = (sellerId) => {
    history.push(`/sellers/${sellerId}/contactInfo`);
  };
  const updateDeliveryContactInfo = (sellerId) => {
    history.push(`/delivery/sellers/${sellerId}/contactInfo`);
  };
  const openSellerAdminsPage = (sellerId) => {
    history.push(`/sellers/${sellerId}/admins`);
  };
  const openUpdateSellerPage = (sellerId) => {
    history.push(`/sellers/${sellerId}/edit`);
  };
  const openUpdateBonusesConfigPage = (sellerId) => {
    history.push(`/sellers/${sellerId}/bonusesConfig`);
  };

  return (
    <React.Fragment>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell className={classes.tableHeader}>{t('Name')}</TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Number of fridges')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Number of orders')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Number of users')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('First order pricing coefficient')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right">
              {t('Timezone')}
            </TableCell>
            <TableCell className={classes.tableHeader} align="right" />
            <TableCell className={classes.tableHeader} align="right" />
            <TableCell className={classes.tableHeader} align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {sellers.map((seller) => (
            <TableRow key={seller.id} className={classes.tableContent}>
              <TableCell component="th" scope="row">
                {seller.name}
              </TableCell>
              <TableCell align="right">{seller.numberOfFridges}</TableCell>
              <TableCell align="right">{seller.numberOfOrders}</TableCell>
              <TableCell align="right">{seller.numberOfUsers}</TableCell>
              <TableCell align="right">{seller.firstOrderPricingCoefficient}</TableCell>
              <TableCell align="right">{seller.timezone}</TableCell>
              <TableCell align="right">
                <Button className={classes.button} onClick={() => generateSellerApiToken(seller.id)}>
                  {t('API token')}
                </Button>
              </TableCell>
              <TableCell align="right" className={classes.actionsFormContainer}>
                <ActionsMenu
                  title={t('Accounts')}
                  options={[
                    {
                      key: 'account',
                      label: t('Create'),
                      handleClick: () => addAccount(seller.id),
                    },
                    {
                      key: 'admins',
                      label: t('List'),
                      handleClick: () => openSellerAdminsPage(seller.id),
                    },
                  ]}
                />
              </TableCell>
              <TableCell align="right" className={classes.actionsFormContainer}>
                <ActionsMenu
                  title={t('Edit')}
                  options={[
                    {
                      key: 'base',
                      label: t('Basic information'),
                      handleClick: () => openUpdateSellerPage(seller.id),
                    },
                    {
                      key: 'payment',
                      label: t('Payment data'),
                      handleClick: () => updatePaymentData(seller.id),
                    },
                    {
                      key: 'notifications',
                      label: t('Notification data'),
                      handleClick: () => updateNotificationsData(seller.id),
                    },
                    {
                      key: 'contacts',
                      label: t('Contact info'),
                      handleClick: () => updateContactInfo(seller.id),
                    },
                    {
                      key: 'deliveryContacts',
                      label: t('Delivery contact info'),
                      handleClick: () => updateDeliveryContactInfo(seller.id),
                    },
                    {
                      key: 'bonuses',
                      label: t('Bonuses'),
                      handleClick: () => openUpdateBonusesConfigPage(seller.id),
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        className={classes.paginationContainer}
        size={'large'}
        variant="outlined"
        shape="rounded"
        count={pagesCount}
        page={activePage}
        onChange={handlePageChange}
        color="primary"
      />
      <ApiTokenModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        handleCopy={handleCopyApiToken}
        sellerId={selectedSellerId}
        apiToken={selectedApiToken}
      />
    </React.Fragment>
  );
};

export default withStyles(styles)(SellersTable);
