import React from 'react';
import Button from '@material-ui/core/Button';
import InputField from '../../fields/Input/InputField';
import { withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { changePassword } from '../../../redux/modules/account/accountActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import notificationTypes from '../../helpers/notificationTypes';
import { showNotification } from '../../helpers/notificationHelper';
import Typography from '@material-ui/core/Typography';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
});

const ChangePasswordForm = (props) => {
  const { classes } = props;
  const isLoading = useSelector((state) => state.account.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  return isLoading ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        return dispatch(changePassword(values))
          .then(() => {
            showNotification(notificationTypes.success, t('Password changed successfully'));
            history.push('/sellers');
          })
          .catch((err) => {
            showNotification(notificationTypes.error, err, t('Changes were not saved'));
          });
      }}
      validationSchema={Yup.object({
        currentPassword: Yup.string()
          .required(t(validationErrors.fieldCantBeEmpty))
          .min(7, t(validationErrors.minPasswordLength)),
        newPassword: Yup.string()
          .required(t(validationErrors.fieldCantBeEmpty))
          .min(7, t(validationErrors.minPasswordLength)),
        confirmPassword: Yup.string()
          .required(t(validationErrors.fieldCantBeEmpty))
          .oneOf([Yup.ref('newPassword'), null], t(validationErrors.passwordsMustMatch)),
      })}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit} className={classes.flexContainer}>
          <div className={classes.childElement}>
            <Typography className={classes.title} color={'primary'} variant={'h5'}>
              {t('Changing password')}
            </Typography>
          </div>
          <InputField
            color={'secondary'}
            className={classes.input}
            name="currentPassword"
            type="password"
            label={t('Current password')}
            variant="outlined"
            autoComplete="current-password"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="newPassword"
            type="password"
            label={t('New password')}
            variant="outlined"
            autoComplete="new-password"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="confirmPassword"
            type="password"
            label={t('New password again')}
            variant="outlined"
            autoComplete="new-password"
          />
          <div className={classes.childElement}>
            <Button disabled={!formikProps.isValid} variant="contained" type="submit" className={classes.button}>
              {t('Save')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(ChangePasswordForm);
