import React from 'react';
import Select from 'react-select';
import { useField } from 'formik';
import { globalThemeObject } from '../../App';
import commonStyles from '../../helpers/commonStyles';

const InnerSelectField = ({ field, form, options, ...custom }) => {
  const [formField, meta] = useField(field.name);
  return (
    <React.Fragment>
      <Select
        value={options ? options.find((option) => option.value === field.value) : ''}
        options={options}
        {...formField}
        onChange={(value) => form.setFieldValue(field.name, value)}
        {...custom}
      />
      {(meta.touched || form.submitCount > 0) && meta.error && (
        <span style={{ color: 'red' }} {...custom}>
          {meta.error}
        </span>
      )}
    </React.Fragment>
  );
};

export default InnerSelectField;

export const customStyles = {
  control: (base, state) => ({
    ...base,
    fontSize: '16px',
    fontFamily: globalThemeObject.typography.fontFamily,
    cursor: 'pointer',
    backgroundColor: globalThemeObject.palette.whitegrey,
    '&:hover': { borderColor: globalThemeObject.palette.black },
    border: '1px solid rgba(0, 0, 0, 0.23)',
    boxShadow: 'none',
  }),
  option: commonStyles.selectComponentStyles.option,
  menu: commonStyles.selectComponentStyles.menu,
};
