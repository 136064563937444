import React from 'react';
import InnerSelectField from './InnerSelectField';
import paymentGatewayTypes from '../../helpers/paymentGatewayTypesForSelect';
import { Field } from 'formik';

const PaymentGatewaySelectField = (props) => (
  <Field
    name={props.name}
    options={paymentGatewayTypes.map((pg) => {
      return { value: pg.id, label: pg.name };
    })}
    component={InnerSelectField}
    {...props}
  />
);

export default PaymentGatewaySelectField;
