import React from 'react';
import { withStyles } from '@material-ui/core';
import HeaderBar from '../../common/HeaderBar/HeaderBar';
import AddSellerAccountForm from './AddSellerAccountForm';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 auto',
    flexDirection: 'column',
    paddingTop: '58px',
  },
  contentWrapper: {
    display: 'flex',
    minHeight: 'calc(100vh - 58px)',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.whitegrey,
    overflow: 'auto',
  },
});

const AddSellerAccountPage = (props) => {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <HeaderBar pageTitle={t('Sellers')} />
        <div className={classes.contentWrapper}>
          <AddSellerAccountForm />
        </div>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(AddSellerAccountPage);
