import { loginSucceeded, loginFailed, requestPending } from './loginReducers';
import axios from 'axios';

export const loginUser = (email, password) => (dispatch) => {
  dispatch(requestPending(true));
  return axios
    .post('/account/sign-in', { email, password })
    .then((response) => dispatch(loginSucceeded(response.data)))
    .catch((err) => {
      dispatch(loginFailed(err.response.data));
    });
};
