import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Menu from '../../fields/Menu';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  header: {
    minHeight: '58px',
    background: theme.palette.white,
    alignItems: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    zIndex: 2,
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
  },
  contentWrapper: {
    width: '95%',
    minHeight: '58px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuItemLink: {
    fontSize: '24px',
    color: theme.palette.primary.dark,
    marginLeft: '10px',
    cursor: 'pointer',
    textDecoration: 'none',
    borderRadius: '11px',
  },
  currentPage: {
    color: theme.palette.secondary.dark,
  },
  userInfo: {
    fontWeight: 600,
    color: theme.palette.primary.dark,
    marginLeft: '10px',
    cursor: 'pointer',
  },
});

const HeaderBar = (props) => {
  const { classes, pageTitle } = props;
  const { t } = useTranslation();

  const headerBarLinks = [
    {
      title: t('Sellers'),
      redirectUrl: '/sellers',
    },
  ];
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentUser');
    props.history.push('/login');
  };
  const handleChangePassword = () => {
    props.history.push('/account/password');
  };

  return (
    <React.Fragment>
      <header className={classes.header}>
        <div className={classes.contentWrapper}>
          {headerBarLinks.map((link) => (
            <Typography key={link.title} variant="subtitle2">
              <Link
                className={`${classes.menuItemLink} ${link.title === pageTitle && classes.currentPage}`}
                to={link.redirectUrl}
              >
                {pageTitle}
              </Link>
            </Typography>
          ))}
          <div className={classes.container}>
            <Menu
              placement="bottom-end"
              options={[
                {
                  key: 'changePassword',
                  label: t('Change password'),
                  handleClick: () => handleChangePassword(),
                },
                {
                  key: 'logout',
                  label: t('Log out'),
                  handleClick: () => handleLogout(),
                },
              ]}
            >
              <Typography variant="subtitle2" className={classes.userInfo}>
                {currentUser.email}
              </Typography>
            </Menu>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default compose(withRouter, withStyles(styles))(HeaderBar);
