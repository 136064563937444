import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Spinner from '../../common/ProgressIndicators/Spinner';
import AddIcon from '@material-ui/icons/Add';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSellers } from '../../../redux/modules/sellers/sellersActions';
import SellersTable from './SellersTable';
import { getPageNumber } from '../../helpers/queryHelper';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },
  gridItem: {
    margin: '10px',
  },
  addButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    backgroundColor: theme.palette.white,
    minWidth: '1300px',
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
  },
});

const SellersList = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let location = useLocation();
  const activePage = getPageNumber(location);

  const sellers = useSelector((state) => state.sellers.sellers);
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const sellersCount = useSelector((state) => state.sellers.sellersCount);

  useEffect(() => {
    dispatch(getSellers(activePage));
  }, [activePage, dispatch]);

  const history = useHistory();

  const handlePageChange = (event, pageNumber) => {
    const queryString = `page=${pageNumber}`;
    history.push({
      pathname: history.location.pathname,
      search: queryString,
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="row" spacing={0} className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Typography className={classes.title} variant={'h6'}>
              {t('Sellers list')}
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button
              startIcon={<AddIcon />}
              variant={'contained'}
              className={classes.addButton}
              onClick={() => history.push('/sellers/add')}
            >
              {t('Add seller')}
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Spinner />
        ) : (
          <SellersTable
            sellers={sellers}
            activePage={activePage}
            handlePageChange={handlePageChange}
            sellersCount={sellersCount}
            history={history}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(SellersList);
