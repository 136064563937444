import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import * as Yup from 'yup';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { getSellerById, updateSellerContactInfo } from '../../../redux/modules/sellers/sellersActions';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import validationErrors from '../../helpers/validationErrors';
import Typography from '@material-ui/core/Typography';
import InputField from '../../fields/Input/InputField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import regularExpressions from '../../helpers/regularExpressions';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
});

const UpdateSellerContactInfoForm = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const selectedSeller = useSelector((state) => state.sellers.selectedSeller);
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const sellerId = getRouteParamFromUrl(match, 'id');

  useEffect(() => {
    dispatch(getSellerById(sellerId));
  }, [dispatch, sellerId]);

  const contactInfoForm = () => {
    return (
      <Form className={classes.flexContainer}>
        <div className={classes.childElement}>
          <Typography className={classes.title} color={'primary'} variant={'h5'}>
            {t('Contact info')}
          </Typography>
        </div>
        <InputField
          color={'secondary'}
          className={classes.input}
          name="phoneNumber"
          type="text"
          label={t('Phone number')}
          variant="outlined"
        />
        <InputField
          color={'secondary'}
          className={classes.input}
          name="email"
          type="email"
          label={t('Email address')}
          variant="outlined"
        />
        <div className={classes.childElement}>
          <Button variant="contained" type="submit" className={classes.button}>
            {t('Save')}
          </Button>
        </div>
      </Form>
    );
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={{
        email: selectedSeller?.email ?? '',
        phoneNumber: selectedSeller?.phoneNumber ?? '',
      }}
      onSubmit={(values, actions) => {
        return dispatch(updateSellerContactInfo(values, sellerId))
          .then(() => {
            history.push('/sellers');
          })
          .catch((err) => {
            showNotification(notificationTypes.error, err, t('Changes were not saved'));
          });
      }}
      validationSchema={Yup.object({
        email: Yup.string().required(t(validationErrors.fieldCantBeEmpty)).email(t(validationErrors.wrongEmailFormat)),
        phoneNumber: Yup.string()
          .required(t(validationErrors.fieldCantBeEmpty))
          .matches(regularExpressions.phonueNumberRegEx, t(validationErrors.wrongPhoneNumberFormat)),
      })}
    >
      {contactInfoForm}
    </Formik>
  );
};

export default withStyles(styles)(UpdateSellerContactInfoForm);
