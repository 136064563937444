import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import * as Yup from 'yup';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { getSellerById, updateSeller } from '../../../redux/modules/sellers/sellersActions';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import UpdateSellerForm from './UpdateSellerForm';
import validationErrors from '../../helpers/validationErrors';
import { useTranslation } from 'react-i18next';

const UpdateSellerFormWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const sellerId = getRouteParamFromUrl(match, 'id');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getSellerById(sellerId, history));
  }, [dispatch, history, sellerId]);
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const selectedSeller = useSelector((state) => state.sellers.selectedSeller);

  return isLoading ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={{
        ...selectedSeller,
      }}
      onSubmit={(values, actions) => {
        return dispatch(updateSeller(values)).then(() => {
          history.push('/sellers');
        });
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(validationErrors.fieldCantBeEmpty),
        firstOrderPricingCoefficient: Yup.number()
          .typeError(t(validationErrors.fieldShouldBePositiveNumber))
          .min(0, t(validationErrors.numberMustBeInRange, { min: 0, max: 1 }))
          .max(1, t(validationErrors.numberMustBeInRange, { min: 0, max: 1 }))
          .required(t(validationErrors.fieldCantBeEmpty)),
        timeZone: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
      })}
    >
      {(formikProps) => <UpdateSellerForm formikProps={formikProps} />}
    </Formik>
  );
};

export default UpdateSellerFormWrapper;
