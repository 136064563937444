import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import paymentGatewayTypes from '../../helpers/paymentGatewayTypes';
import i18next from 'i18next';

const t = i18next.t;

const buildRequiredDependingOnPaymentGateway = (paymentGateway) => ({
  is: (field) => field && field.value === paymentGateway,
  then: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
});

const validationShape = () => {
  return {
    authorizationAmount: Yup.number()
      .typeError(t(validationErrors.fieldShouldBePositiveNumber))
      .required(t(validationErrors.fieldCantBeEmpty)),
    currency: Yup.string().required(t(validationErrors.fieldCantBeEmpty)),
    paymentGateway: Yup.object().required(t(validationErrors.fieldCantBeEmpty)),
    shopId: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.bePaid)),
    publicKey: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.bePaid)),
    privateKey: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.bePaid)),
    apiSecret: Yup.string().when(
      'paymentGateway',
      buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.cloudPayments),
    ),
    publicId: Yup.string().when(
      'paymentGateway',
      buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.cloudPayments),
    ),
    userName: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.sberbank)),
    password: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.sberbank)),
  };
};

export { validationShape };
